import React, { lazy } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const Home = lazy(() => import('./Pages/home'));
const Movies = lazy(() => import('./Pages/movie'));
const Series = lazy(() => import('./Pages/series'));
const Navbar = lazy(() => import('./Components/Navbar/navbar'));
const Footer = lazy(() => import('./Components/Footer/footer'));
const Pages = lazy(() => import('./Pages/pages'));
const MovieDetails = lazy(() => import('./Pages/movie_details'));
const SeriesDetails = lazy(() => import('./Pages/series_details'));
const SeriesEpisode = lazy(() => import('./Pages/series_episode'));
const LiveTV = lazy(() => import('./Pages/live_tv'));
const LiveTVDetail = lazy(() => import('./Pages/live_tv_detail'));
const UserProfile = lazy(() => import('./Pages/profile'));
const MyList = lazy(() => import('./Pages/my_list'));
const Apps = lazy(() => import('./Pages/Apps'));

const Login = lazy(() => import('./Pages/Login'));
const Signup = lazy(() => import('./Pages/signup'));
const LoginMobile = lazy(() => import("./Pages/LoginMobile"));
const LoginOTP = lazy(() => import("./Pages/LoginOTP"));
function App() {
  const user = null;

  return (
    <div className="App">
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/index" element={<Home />} />
          <Route exact path="/movies" element={<Movies />} />
          <Route exact path="/movie-detail/:slug" element={<MovieDetails />} />
          <Route exact path="/series" element={<Series />} />
          <Route exact path="/series-detail/:slug" element={<SeriesDetails />} />
          <Route exact path="/series-detail/:slug/:episode" element={<SeriesEpisode />} />
          <Route exact path="/live-tv" element={<LiveTV />} />
          <Route exact path="/live-tv/:slug" element={<LiveTVDetail />} />
          <Route exact path="/profile" element={<UserProfile />} />
          <Route exact path="/my-list" element={<MyList />} />
          <Route exact path="/wo/:slug" element={<Pages />} />
          <Route exact path="/apps" element={<Apps />} />
        </Routes>
        {!user ? (
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/login-mobile" element={<LoginMobile />} />
              <Route exact path="/signup" element={<Signup />} />
              <Route exact path="/login-otp" element={<LoginOTP />} />
              
            </Routes>
          ) : (
            <></>
          )
        }
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;